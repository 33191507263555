<template>

    <div id="app" class="white pt-1 ma-4" v-if="(dre_show)">

        <!-- {{ items_dre }} -->
        <!-- <h4>DRE</h4> -->
        <!-- dre_show: {{ dre_show }} -->

        <!-- items_dre: {{ items_dre }} <br/> -->

        <v-row class="">
            <v-col class="pa-1 grey lighten-2">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" md="2" lg="1" class="ml-2">
                                <v-select
                                    v-model="selectedYear"
                                    :items="years"
                                    label="Ano"
                                    hide-details
                                ></v-select>
                            </v-col>
                            
                            <v-col cols="6" md="3" lg="2" class="">
                                <v-checkbox
                                    v-model="check_despesas"
                                    dense hide-details
                                    class="py-0 ma-0 mt-n1"   
                                >
                                   <template v-slot:label>
                                      <span :class="['fs-12']">Total de Despesas</span>
                                    </template>
                                </v-checkbox>
                                
                                <v-checkbox
                                    v-model="check_categorias"
                                    dense hide-details
                                    class="py-0 ma-0 mt-n1"   
                                >
                                   <template v-slot:label>
                                      <span :class="['fs-12']">Categorias</span>
                                    </template>
                                </v-checkbox>
                                
                                <v-checkbox
                                    v-model="check_operacoes"
                                    dense hide-details
                                    class="py-0 ma-0 mt-n1"   
                                >
                                   <template v-slot:label>
                                      <span :class="['fs-12']">Operações</span>
                                    </template>
                                </v-checkbox>
                                
                            </v-col>

                            <v-col cols="6" md="3" lg="2" class="" v-if="0>1">
                                <v-btn
                                    color="primary"
                                    small
                                    @click="imprimir_conteudo('DRE', headers, items_dre, `DRE - ${selectedYear}`, false, true, '100%')"
                                    class="mr-5"
                                    v-if="(!isMobile)"
                                >
                                    Imprimir
                                </v-btn>

                            </v-col>

                            <v-toolbar
                                dark
                                color="white lighten-5"
                                elevation="0"
                                class="no-print"
                            >
                
                
                                <v-spacer></v-spacer>
                
                                <v-btn
                                    @click="imprimir_conteudo('DRE', headers, items_dre, `DRE - ${selectedYear}`, false, true,'100%')"
                                    color="primary text--white"
                                >
                                <v-icon color="white"> mdi-printer </v-icon>
                                &nbsp imprimir
                                </v-btn>
                
                                <v-divider class="mx-2" vertical inset></v-divider>
                
                
                                <v-btn
                                    color="primary"
                                    @click="close()"
                                >
                                    
                                    <v-icon>mdi-close</v-icon>
                                    &nbsp 
                                    Fechar
                                    
                                </v-btn>
                
                            </v-toolbar>

                        </v-row>
                    </v-card-text>
            </v-card>

            </v-col>
        </v-row>
        
        <v-divider class="ma-4"></v-divider>


        <v-row class="">
            <v-col class="pa-1 grey lighten-2">

                <v-card>
                    <v-card-text>

                        <v-row class="mt-1 px-0">
                            <v-col class="pa-0  mt-n4 mb-1 " cols="12">
                                
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="">
                                <v-data-table
                                    v-model="selected"

                                    fixed-header

                                    :hide-default-header="false"
                                    :mobile-breakpoint="55"

                                    :headers="headers"
                                    :items="items_dre"
                                    class="elevation-1"
                                    :items-per-page="-1"
                                    :item-class= "rowClass" 
                                    :hide-default-footer = "true"
                                    dense
                                    >           
            
                                    <!-- <template v-slot:item.DESCRICAO="{ item }">
                                        <span class="fs-12 bold"> 
                                          {{ item.DESCRICAO }} 
                                        </span>
                                    </template> -->
                                    
                               
                                    <!-- Slot para a coluna DESCRICAO -->
                                    <template v-slot:item.DESCRICAO="{ item }">
                                        <span class="highlight-text">{{ item.DESCRICAO }}</span>
                                    </template>

                                    <!-- Slots para as colunas MES_1 até MES_12 -->
                                    <template v-for="i in 12" v-slot:[`item.MES_${i}`]="{ item }">

                                        <!-- NEGRITO SOMENTE EM ALGUMAS LINHAS -->
                                        <span class="highlight-text" v-if="['SALDO','RECEBIMENTOS','TOTAL DE DESPESAS'].includes(item.DESCRICAO)">
                                            {{ item[`MES_${i}`] }}
                                        </span>
                                        <span class="fs-12" v-else>
                                            {{ item[`MES_${i}`] }}
                                        </span>
                                    </template>


                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>

    </div>
    </template>
    
    <script>
    
    import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
    import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo

    
    
    export default {

    

      data: () => ({    
    
            items_dre        : [],
            items_entradas   : [],
            items_saidas     : [],
            items_categorias : [],
            

            check_despesas   : true,
            check_categorias : false,
            check_operacoes  : false,

            dre_show : false,

            headers: [
                { text: 'DESCRICAO', value: 'DESCRICAO' },
                { text: 'JANEIRO', value: 'MES_1' },
                { text: 'FEVEREIRO', value: 'MES_2' },
                { text: 'MARÇO', value: 'MES_3' },
                { text: 'ABRIL', value: 'MES_4' },
                { text: 'MAIO', value: 'MES_5' },
                { text: 'JUNHO', value: 'MES_6' },
                { text: 'JULHO', value: 'MES_7' },
                { text: 'AGOSTO', value: 'MES_8' },
                { text: 'SETEMBRO', value: 'MES_9' },
                { text: 'OUTUBRO', value: 'MES_10' },
                { text: 'NOVEMBRO', value: 'MES_11' },
                { text: 'DEZEMBRO', value: 'MES_12' }
            ],

            selectedYear: new Date().getFullYear(), // Define o ano atual como padrão
            years: [],

            w_data: '99px',
            w_fone: '125px',
            w_cpf: '120px',
            w_cnpj: '150px',
            w_nome: '220px',
            w_situacao: '60px',
            w_valor: '90px',

            // ------------ TABELAS INICIO ---------------
            icone_tabela_sel:'',
            selected: [],

            selectedItem: -1,
            card_titulo:'',
            dialog_tabela: false,
            tabela_selecionada:'',
            search: '',

            lista_tabelas:[],
            selected: [],
            items_lista:[],
            items_header: [],
            items_editaveis: [],

            item_edita: {},

            sub_codigo:0,
            sub_descricao:'',

            valido: false,
            
            size_mobile:22,

            notnullRules: [
            v => !!v || 'necessário'
            ],

            noRules:[v => true],


            // ------------ TABELAS FIM ---------------

            snackbar: false,
            snack_text: 'Nova Mensagem',
            snack_color: "success",

        }),
    
    
      created(){
        this.onCreated()
      },

    
      watch:{

        selectedYear(a){
            this.abrir_dre()
        },
        
        check_despesas(a){
            this.abrir_dre()
        },
        
        check_categorias(a){
            this.abrir_dre()
        },
        
        check_operacoes(a){
            this.abrir_dre()
        },
      },
    

    
      methods:{

        
        async onCreated(){
            await this.ler_sessions()

            await this.abrir_dre()
        },

        close(){
          window.close()
        },


        async imprimir_conteudo(modelo_relatorio, headers, items, titulo, ocultar_header, auto_print, largura){

            let componente = ''

            if (items.constructor === Array){
                componente = 'v-data-table'
                headers = JSON.stringify(headers)
                items = JSON.stringify(items)
            }
            else{
                componente = 'v-textarea'
            }


            // monto o objeto que sera lido la em relatorios.vue
            let obj_dados_impressao = {}

            this.$set(obj_dados_impressao, 'relatorios_show'    , true) //permite ver relatorio
            this.$set(obj_dados_impressao, 'qual'               , 'AUTO')
            this.$set(obj_dados_impressao, 'modelo_relatorio'   , modelo_relatorio)
            this.$set(obj_dados_impressao, 'componente'         , componente)
            this.$set(obj_dados_impressao, 'headers'            , headers)
            this.$set(obj_dados_impressao, 'items'              , items)
            this.$set(obj_dados_impressao, 'titulo'             , titulo)
            this.$set(obj_dados_impressao, 'auto_print'         , auto_print )
            this.$set(obj_dados_impressao, 'ocultar_header'     , ocultar_header) 
            this.$set(obj_dados_impressao, 'largura'            , largura) 

            modelo_relatorio


            // salvo na session para ser lido em relatorios.vue
            sessionStorage['obj_dados_impressao']  =  JSON.stringify(obj_dados_impressao)

            // abro a pagina relatorios.vue
            let route = this.$router.resolve({path: '/relatorios'});
            window.open(route.href, '_blank');


            // apos aberto, volto para false para nao ser lido fora do contexto
            this.$set(obj_dados_impressao, 'relatorios_show'    , false)
        },


        printTable() {
            const printWindow = window.open('', '', 'height=600,width=800');
            printWindow.document.write('<html><head><title>Print Table</title>');
            printWindow.document.write('<style>@media print { @page { size: landscape; margin: 1in; } .highlight-text { font-size: 12px; font-weight: bold; } table { width: 100%; border-collapse: collapse; } td, th { border: 1px solid #000; padding: 8px; text-align: left; } }</style>');
            printWindow.document.write('</head><body >');
            printWindow.document.write(document.querySelector('v-data-table').outerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
        },

        getItemClass(key) {
         // Verifica se a chave está no intervalo de MES_1 a MES_12
          return key.startsWith('MES_') ? 'fs-12 bold' : '';
        },

        async abrir_dre(){
            await this.abrir_entradas()
            await this.abrir_saidas()
            await this.abrir_categorias()
        },

        async abrir_categorias(){
            
            if (!this.check_categorias){
                return false
            }


            this.items_saidas   = []

            let sql   = ``
            let sql_2 = ``


            if (this.check_operacoes){
                sql_2 = `
                  CASE 
                    WHEN CONTA.TIPO = 'E' THEN CONTA.SERVICO_REFERENTE
                    WHEN CONTA.OPERACAO IS NULL OR CONTA.OPERACAO = '' THEN CONTA.SERVICO_REFERENTE
                    ELSE CONTA.OPERACAO
                  END AS OPERACAO,
                `
            }

            sql = `
                 SELECT
                    MES.MES,
                    CONTA.CATEGORIA,
                    ${sql_2}
                    CONTA.TIPO,
                    SUM(CONTA.double_TOTAL) AS SUM_PAGO
                 FROM
                    (SELECT 1 AS MES UNION ALL
                    SELECT 2 AS MES UNION ALL
                    SELECT 3 AS MES UNION ALL
                    SELECT 4 AS MES UNION ALL
                    SELECT 5 AS MES UNION ALL
                    SELECT 6 AS MES UNION ALL
                    SELECT 7 AS MES UNION ALL
                    SELECT 8 AS MES UNION ALL
                    SELECT 9 AS MES UNION ALL
                    SELECT 10 AS MES UNION ALL
                    SELECT 11 AS MES UNION ALL
                    SELECT 12 AS MES) AS MES

                 LEFT JOIN CONTA 
                    ON MONTH(CONTA.DATA_EFETUADO) = MES.MES
                    AND YEAR(CONTA.DATA_EFETUADO) = ${this.selectedYear}  
                    AND CONTA.TIPO = 'S'
                 GROUP BY
                    MES.MES,
                    CONTA.CATEGORIA,
                    OPERACAO, -- Usando o alias da cláusula CASE no SELECT
                    CONTA.TIPO,
                    CONTA.SERVICO_REFERENTE
                 ORDER BY
                    MES.MES,
                    CONTA.TIPO,
                    CONTA.CATEGORIA;

                `
            

            console.log('sql:', sql);

            await this.crud_abrir_tabela(sql, 'items_categorias')
            // console.log('items_categorias:', this.items_categorias);
            


            if (!this.check_operacoes){
                await this.separa_categorias(this.items_categorias)
            }



            // Criar um novo array
            const newArray = [];

            // Adicionar uma linha em branco no começo do array
            newArray.unshift({ DESCRICAO: ' ', MESES: {} });

            // Preencher o novo array com categorias e meses
            this.items_categorias.forEach(item => {
            let categoriaIndex = newArray.findIndex(cat => cat.DESCRICAO === item.CATEGORIA);

            // Obter o valor numérico de SUM_PAGO
            let sumPago = parseFloat(item.SUM_PAGO);

            // Se a categoria não existir no array, adiciona uma nova entrada
            if (categoriaIndex === -1) {
                newArray.push({
                DESCRICAO: item.CATEGORIA,
                [`MES_${item.MES}`]: sumPago
                });
            } else {
                // Se a categoria já existe, adiciona ou atualiza o valor do mês correspondente
                if (!newArray[categoriaIndex][`MES_${item.MES}`]) {
                newArray[categoriaIndex][`MES_${item.MES}`] = sumPago;
                } else {
                newArray[categoriaIndex][`MES_${item.MES}`] += sumPago;
                }
            }
            });

            // Formatar os valores para o formato 0.0,0
            newArray.forEach(categoria => {
            for (let mes in categoria) {
                if (mes.startsWith('MES_')) {
                categoria[mes] = this.formatCurrency(categoria[mes]);
                }
            }
            });

            // console.log('newArray:', newArray);
            this.items_dre = this.items_dre.concat(newArray);

        },


        async separa_categorias(items) {
            // console.log('items:::', items);
            
            const novoArray = items.map(item => {
                let CATEGORIA = item.CATEGORIA || ''; // Garantir que CATEGORIA seja uma string
                let OPERACAO = '';

                // Verificar se CATEGORIA contém o separador ' - '
                if (CATEGORIA.includes(' - ')) {
                    [CATEGORIA, OPERACAO] = CATEGORIA.split(' - ');
                }

                return {
                    ...item,
                    CATEGORIA,
                    OPERACAO
                };
            });

            this.items_categorias = novoArray;
        },

        async abrir_saidas(){
            
            if (!this.check_despesas){
                return false
            }


            this.items_saidas   = []

            let sql = ``

            sql = `
                SELECT
                MES.MES,
                CONTA.TIPO,
                SUM(
                    CASE
                    WHEN CONTA.double_TOTAL IS NOT NULL THEN CONTA.double_TOTAL
                    ELSE 0
                    END
                ) AS TOTAL_DE_DESPESAS
                FROM
                (SELECT 1 AS MES UNION ALL
                SELECT 2 AS MES UNION ALL
                SELECT 3 AS MES UNION ALL
                SELECT 4 AS MES UNION ALL
                SELECT 5 AS MES UNION ALL
                SELECT 6 AS MES UNION ALL
                SELECT 7 AS MES UNION ALL
                SELECT 8 AS MES UNION ALL
                SELECT 9 AS MES UNION ALL
                SELECT 10 AS MES UNION ALL
                SELECT 11 AS MES UNION ALL
                SELECT 12 AS MES) AS MES
                LEFT JOIN CONTA 
                ON MONTH(CONTA.DATA_EFETUADO) = MES.MES 
                AND YEAR(CONTA.DATA_EFETUADO) = ${this.selectedYear} 
                AND CONTA.TIPO = 'S'
                GROUP BY
                MES.MES,
                CONTA.TIPO
                ORDER BY
                MES.MES;
                `
            

            console.log('items_saidas.sql:', sql);

            await this.crud_abrir_tabela(sql, 'items_saidas')
            // console.log('items_saidas:', this.items_saidas);
            

            // Criar um novo array
            const newArray = [
              { DESCRICAO: 'TOTAL DE DESPESAS', MESES: {} },
            ];

            // Preencher o novo array
            this.items_saidas.forEach(item => {
              newArray[0][`MES_${item.MES}`] = this.formatCurrency(item.TOTAL_DE_DESPESAS);
            });

            // console.log('newArray:', newArray);
            
            // this.items_dre = newArray
            this.items_dre = this.items_dre.concat(newArray);



            await this.addSaldoRow();
            

        },

        async abrir_entradas(){
                
            this.items_dre      = []
            this.items_entradas = []

            let sql = ``

            sql = `
                SELECT
                MES.MES,
                
                SUM(CASE
                WHEN
                    (YEAR(CONTA.DATA_VENDA) = ${this.selectedYear}) AND
                    (MONTH(CONTA.DATA_VENDA) = MES.MES) AND
                    (CONTA.PAGO='S')
                    THEN CONTA.double_TOTAL
                ELSE 0
                END) AS VENDAS_A_VISTA,

                SUM(CASE
                WHEN
                    (YEAR(CONTA.DATA_VENDA) = ${this.selectedYear}) AND
                    (MONTH(CONTA.DATA_VENDA) = MES.MES) AND
                    (CONTA.PAGO='N')
                    THEN CONTA.double_TOTAL
                ELSE 0
                END) AS VENDAS_A_PRAZO_PENDENTE,

                SUM(CASE
                WHEN
                    (YEAR(CONTA.DATA_VENDA) = ${this.selectedYear}) AND
                    (MONTH(CONTA.DATA_VENDA) = MES.MES)
                    THEN CONTA.double_TOTAL
                ELSE 0
                END) AS TOTAL_VENDAS,

                SUM(CASE
                WHEN
                    (YEAR(CONTA.DATA_EFETUADO) = ${this.selectedYear}) AND
                    (MONTH(CONTA.DATA_EFETUADO) = MES.MES)
                    THEN CONTA.double_TOTAL
                ELSE 0
                END) AS RECEBIMENTOS

                FROM
                (SELECT 1 AS MES UNION ALL
                SELECT 2 AS MES UNION ALL
                SELECT 3 AS MES UNION ALL
                SELECT 4 AS MES UNION ALL
                SELECT 5 AS MES UNION ALL
                SELECT 6 AS MES UNION ALL
                SELECT 7 AS MES UNION ALL
                SELECT 8 AS MES UNION ALL
                SELECT 9 AS MES UNION ALL
                SELECT 10 AS MES UNION ALL
                SELECT 11 AS MES UNION ALL
                SELECT 12 AS MES) AS MES
                LEFT JOIN CONTA ON MONTH(CONTA.DATA_VENDA) = MES.MES OR MONTH(CONTA.DATA_EFETUADO) = MES.MES
                WHERE
                (CONTA.TIPO = 'R') OR
                (CONTA.TIPO = 'E')
                GROUP BY
                MES.MES
                ORDER BY
                MES.MES;
                `
            

            // console.log('sql:', sql);

            await this.crud_abrir_tabela(sql, 'items_entradas')



            // Criar um novo array
            const newArray = [
            { DESCRICAO: 'VENDAS A VISTA', MESES: {} },
            { DESCRICAO: 'VENDAS A PRAZO PENDENTE', MESES: {} },
            { DESCRICAO: 'TOTAL VENDAS', MESES: {} },
            { DESCRICAO: 'RECEBIMENTOS', MESES: {} },
            ];

            // Preencher o novo array
            this.items_entradas.forEach(item => {
            newArray[0][`MES_${item.MES}`] = this.formatCurrency(item.VENDAS_A_VISTA);
            newArray[1][`MES_${item.MES}`] = this.formatCurrency(item.VENDAS_A_PRAZO_PENDENTE);
            newArray[2][`MES_${item.MES}`] = this.formatCurrency(item.TOTAL_VENDAS);
            newArray[3][`MES_${item.MES}`] = this.formatCurrency(item.RECEBIMENTOS);
            });

            // console.log('newArray:', newArray);
            
            // this.items_dre = newArray
            this.items_dre = this.items_dre.concat(newArray);            

        },


        rowClass(item){
            let r = this.getColorLinha(item)
            return r
        },

        getColorLinha (item) {

            // console.log('item:', item);
            
            let r = ''

                 if (item.DESCRICAO == 'VENDAS A VISTA')           r =  'blue lighten-5'
            else if (item.DESCRICAO == 'VENDAS A PRAZO PENDENTE')  r =  'orange lighten-5'
            else if (item.DESCRICAO == 'TOTAL VENDAS')             r =  'green lighten-5'
            else if (item.DESCRICAO == 'TOTAL DE DESPESAS')        r =  'red lighten-4'
            else if (item.DESCRICAO == 'RECEBIMENTOS')             r =  'green lighten-4'
            else if (item.DESCRICAO == 'SALDO')                    r =  'blue lighten-4'
            else r = ''

            return r
        },


        async addSaldoRow() {
            try {
                // Simula uma operação assíncrona, como uma chamada de API
                await new Promise(resolve => setTimeout(resolve, 100)); // Simulação de atraso

                const recebtRow = this.items_dre.find(row => row.DESCRICAO === "RECEBIMENTOS");
                const despRow = this.items_dre.find(row => row.DESCRICAO === "TOTAL DE DESPESAS");

                if (recebtRow && despRow) {
                const saldo = {};
                for (let i = 1; i <= 12; i++) {
                    const mesKey = `MES_${i}`;
                    const receb = parseFloat(recebtRow[mesKey].replace('.', '').replace(',', '.'));
                    const desp = parseFloat(despRow[mesKey].replace('.', '').replace(',', '.'));
                    saldo[mesKey] = (receb - desp).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                }
                
                this.items_dre.push({
                    "DESCRICAO": "SALDO",
                    "MESES": {},
                    ...saldo
                });
                }
            } catch (error) {
                console.error('Erro ao calcular saldo:', error);
            }
            },

         formatCurrency(value) {
          let moeda = ''//R$ 

          return moeda + parseFloat(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
        },

        async ler_sessions(){


            this.dre_show = sessionStorage['dre_show'] == 'S'


            if (!this.dre_show){
                window.location.replace("https://www.google.com") 
                return false
            }

            const currentYear = new Date().getFullYear();
            for (let i = 0; i < 10; i++) {
                this.years.push(currentYear - i);
            }

            console.log('ler_sessions uma tag..se nula fecha janela..para nao usar o logado aqui');
        },
    

        getFormatCurrency(v,string){
          if (v){
            return DSibasico.getFormatCurrency(v, string)
          }
          else{
            return v
          }
          
        },

        async tabela(tabela, item){
        //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

            // console.log(item);
            // this.icone_tabela_sel = item.icon
            
            if (!item){
             sessionStorage['tabela_clicou']   = tabela
             sessionStorage['tabela_titulo']   = this.card_titulo
            }


            this.search = ''//limpa filtro

            this.tabela_selecionada = tabela


            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

        

            if (tabela=='WHATSAPP'){

                
            }

        },

        async before_update(tabela){
        // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


            // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
            let VERIFICAR = [
                // 'LANCAMENTOS|DATA|null',
            ]
            
            let CAMPOS      = []
            let CAMPO_VER   = ''
            let CAMPO_VALOR = ''
            for (let i = 0; i < VERIFICAR.length; i++) {
                
                CAMPOS      = VERIFICAR[i].split("|");//divide
                CAMPO_VER   = CAMPOS[1]
                CAMPO_VALOR = this.item_edita[CAMPO_VER]

                if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                    if (!CAMPO_VALOR){//verifica se vazio
                    this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                    }
                }
            }
        },

        async ultimo_click(){


          let tabela = sessionStorage['tabela_clicou']

          if (this.pro_tabela){
            this.card_titulo = this.pro_titulo
            await this.tabela(this.pro_tabela,'')

            sessionStorage['tabela_clicou'] = this.pro_tabela
          }
          else if (tabela){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              await this.tabela(tabela,'')
          }


          
          //   ja abre em modo edicao
          if (this.pro_modo_edicao){

              if (this.pro_obj_1){
                // alert('editar')
                this.editar_tabela(this.pro_obj_1, 'item_edita', 'dialog_tabela')
              }
              else{
                // alert('novo')
                this.nova_tabela('item_edita','dialog_tabela')
              }
         }

        },

        meu_keyup(event,campo,upper){
  
          //identifica se o campo é double e muda o display
          if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] =  this.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
          }
          else if (campo.includes('CEP')) {
  
              this.consulta_cep(this.item_edita[campo])    
          }
          else{
              if ((campo.includes('EMAIL')==false) &&
              (campo.includes('SENHA')==false) &&
              (campo.includes('SENHA_WEB')==false)){
  
                  if (upper !=='N'){
                  this.allUpper(event, campo)
                  }
                  
              }
          }
        },

        allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event

              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                event.target.value = a
              } 
          },


        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
          },
 

        define_defaults(tabela){

            this.refresh_data_hora()

            // passo 7 defino valores default para alguns campos de determinada tabela
            if (tabela=='TAREFAS'){
             this.$set(this.item_edita, 'CODIGO_OPERADOR', this.user.CODIGO)
             this.$set(this.item_edita, 'VISTO'          , 'N')
             this.$set(this.item_edita, 'DATA'           , this.today)
            }
            else if (tabela=='ALERTA'){
             this.$set(this.item_edita, 'CODIGO_OPERADOR', this.user.CODIGO)
             this.$set(this.item_edita, 'VISTO'          , 'N')
             this.$set(this.item_edita, 'TIPO'           , 'LEMBRETE')
            }

        },

        async seleciona_linha(row) {
        // console.log('row:');
        // console.log(row);
             this.selected =  [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
        },    
    
    
    
        // --------------------------------SQL-------------------------------------
    
        //ABRE OS DADOS DA TABELA
        async crud_abrir_tabela(sql, items_name){
            await DSisql.crud_abrir_tabela(this, sql, items_name)
    
            // console.log(this.items_mensagens);
        }, 
    
        async crud_sql (sql) {
            await DSisql.crud_sql(this, sql)
        },
    
        alerta(text,color){
            DSibasico.alerta(this, text, color)
        },

        dif_datas(a,b){
            return DSibasico.dif_datas(a,b)
        },
    
        incDay(data,dias,pt){
            return DSibasico.incDay(data,dias,pt)
        },
    
        incMonth(data,dias,pt){
            return DSibasico.incMonth(data,dias,pt)
        },
    
        alerta2(text,color){
            // alert(text);
             this.snackbar = true;
             this.snack_text= text;
    
             this.snack_color = color;
        },
    
        define_data_hora(){
            DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
        },
    


           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              await this.seleciona_linha(item)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },


      },
    
    
    }
    </script>
    
    <style>
    .container{
        /* max-width: 100%; */
      }
    
    .names{
      color: #424949;
    }
    
    
      .scrollable {
        overflow-y: auto;
        height: 90vh;
        background-color: blue;
      }
    
      /* box das mensagens */
      .chat-container{
        box-sizing: border-box;
        /* height: calc(100vh - 9.5rem); */
        /* height: 400px; */
        
        height: 50vh; /* 50% altura tela*/
        /* max-height: 600px; */
        /* width: 100vw; 100% largura tela*/
        overflow-y: auto;
        padding: 10px;
        background-color: #f2f2f2;
        /* background-color: white; */
        /* background-color: red; */
      }
      .message{
        /* margin-bottom: 3px; */
      }
      .message.own{
        text-align: right;
      }
      .message.own .content{
        /* background-color: lightgreen; */
        background-color: lightskyblue;
      }
      .chat-container .username{
        font-size: 14px;
        font-weight: bold;
    
      }


    
      .hora{
        font-size: 9px;
      }

      .fs-7{
    font-size: 7px !important;
    }
    .fs-8{
        font-size: 8px !important;
    }
    .fs-9{
        font-size: 9px !important;
    }
    .fs-10{
        font-size: 10px !important;
    }
    .fs-11{
        font-size: 11px !important;
    }
    .fs-12{
        font-size: 12px !important;
    }
    .fs-13{
        font-size: 13px !important;
    }
    .fs-14{
        font-size: 14px !important;
    }
    .fs-15{
        font-size: 15px !important;
    }
    .fs-16{
        font-size: 16px !important;
    }
    .fs-17{
        font-size: 17px !important;
    }
    .bold{
        font-weight: bold !important;
    }

    .highlight-text {
      font-size: 12px;
      font-weight: bold;
    }


    @media print {
    /* Ajuste a orientação da página para horizontal */
    @page {
        size: landscape;
        margin: 1in;
    }

    /* Ajuste a tabela para ocupar toda a largura da página */
    .print-container {
        width: 100%;
        overflow: hidden;
    }

    .print-container table {
        width: 100%;
        border-collapse: collapse;
    }

    .print-container td,
    .print-container th {
        border: 1px solid #000;
        padding: 8px;
        text-align: left;
    }
    }

    
    </style>